import {
  Box,
  type BoxProps,
  Button,
  Divider,
  Flex,
  Icon,
  Image,
  Link,
  Spacer,
  chakra,
  useColorMode,
} from '@chakra-ui/react';
import { getMe } from '@src/api';
import logoImage from '@src/assets/logomark.svg';
import logoImageDark from '@src/assets/logomarkDark.svg';
import { useDisclosure } from '@src/hooks';
import { useTokens } from '@src/identityService';
import { useQuery } from '@tanstack/react-query';
import { type FC, lazy } from 'react';
import { NavLink, Link as RouterLink, useNavigate } from 'react-router-dom';
import Plus from './plus.svg?react';

// Depends on react-hook-form, so lazy load to reduce bundle size.
const FeedbackDrawer = lazy(() => import('@src/components/FeedbackDrawer'));

type Props = BoxProps;

const ChakraNavLink = chakra(NavLink);

const TopDesktopNavBar: FC<Props> = ({ ...props }) => {
  const tokens = useTokens();
  const meQuery = useQuery({
    queryKey: ['me'],
    queryFn: getMe,
    enabled: !!tokens,
  });
  const feedbackDrawerDisclosure = useDisclosure({
    name: 'Top Desktop Nav Bar Feedback',
  });
  const navigate = useNavigate();
  const { colorMode } = useColorMode();

  return (
    <Box
      as="nav"
      position="sticky"
      top={0}
      zIndex={2}
      bg="neutral.bg.0"
      px="regular"
      py="deci"
      borderBottom="1px"
      borderColor="neutral.9"
      {...props}
    >
      <Box
        pt="env(safe-area-inset-top)"
        pl="env(safe-area-inset-left)"
        pr="env(safe-area-inset-right)"
        position="relative"
      >
        <Link
          href="#main"
          position="absolute"
          zIndex="999"
          padding="deci"
          bg="neutral.bg.0"
          css={{
            clip: 'rect(1px,1px,1px,1px)',
          }}
          overflow="hidden"
          _focus={{
            clip: 'auto',
            left: '0%',
          }}
        >
          Skip to main content
        </Link>
        <Flex align="center" gap="regular" textStyle="popPuffin">
          <Link as={RouterLink} to="/" color="neutral.1.base">
            <Image
              src={colorMode === 'dark' ? logoImageDark : logoImage}
              width="30px"
              height="30px"
              alt="Unstuck home"
            />
          </Link>
          {tokens &&
            meQuery.data &&
            !meQuery.data.user?.shouldShowOnboarding &&
            !meQuery.data.user?.shouldShowPaywall && (
              <>
                {[
                  { to: '/', text: 'Dashboard' },
                  { to: '/insights', text: 'Insights' },
                  { to: '/learn', text: 'Learn' },
                  { to: '/profile', text: 'Profile' },
                ].map(({ to, text }) => (
                  <ChakraNavLink key={to} to={to} color="neutral.1.base">
                    {({ isActive }) => (
                      <chakra.span fontWeight={isActive ? 700 : 500}>
                        {text}
                      </chakra.span>
                    )}
                  </ChakraNavLink>
                ))}

                <Divider orientation="vertical" height="28px" />
                <Button
                  variant="link"
                  color="neutral.1.base"
                  fontWeight={500}
                  onClick={() => navigate('/entries')}
                  leftIcon={<Icon as={Plus} boxSize="10px" />}
                >
                  New Activity
                </Button>
                <Spacer />
                <Button
                  variant="link"
                  color="neutral.1.base"
                  fontWeight={500}
                  onClick={feedbackDrawerDisclosure.onOpen}
                >
                  Give us feedback
                </Button>
              </>
            )}
          <FeedbackDrawer {...feedbackDrawerDisclosure} />
        </Flex>
      </Box>
    </Box>
  );
};

export default TopDesktopNavBar;
