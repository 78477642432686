export default {
  colors: {
    neutral: {
      1: {
        base: {
          default: 'gray.900',
          _dark: 'gray.100',
        },
        inverse: {
          default: 'white',
          _dark: 'gray.900',
        },
      },
      3: {
        default: 'gray.700',
        _dark: 'gray.200',
      },
      4: {
        default: 'gray.600',
        _dark: 'gray.300',
      },
      7: {
        default: 'gray.300',
        _dark: 'gray.600',
      },
      8: {
        default: 'gray.200',
        _dark: 'gray.700',
      },
      9: {
        default: 'gray.100',
        _dark: 'gray.800',
      },
      bg: {
        0: {
          default: 'white',
          _dark: 'gray.900',
        },
        1: {
          default: 'gray.50',
          _dark: 'gray.800',
        },
      },
    },
    action: {
      accent: {
        default: 'pink.700',
        _dark: 'pink.200',
      },
      disabled: {
        default: 'gray.200',
        _dark: 'gray.700',
      },
      primary: {
        base: {
          default: 'blue.500',
          _dark: 'blue.200',
        },
        active: {
          default: 'blue.500',
          _dark: 'blue.50',
        },
        hover: {
          default: 'blue.500',
          _dark: 'blue.100',
        },
      },
      secondary: {
        default: 'blue.600',
        _dark: 'blue.100',
      },
      selector: {
        deselected: {
          default: 'gray.400',
          _dark: 'gray.500',
        },
        selected: {
          default: 'blue.500',
          _dark: 'blue.200',
        },
      },
      tertiary: {
        default: 'gray.600',
        _dark: 'gray.300',
      },
    },
    info: {
      default: 'pink.700',
      _dark: 'pink.200',
    },
    anxiety: 'earth.500',
    depression: 'sky.500',
    traps: 'blue.200',
    brand: {
      2: {
        default: 'blue.700',
        _dark: 'blue.100',
      },
      3: {
        default: 'blue.600',
        _dark: 'blue.200',
      },
      bg: {
        1: {
          default: 'blue.800',
          _dark: 'blue.100',
        },
        10: {
          default: 'blue.30',
          _dark: 'gray.800',
        },
      },
      gradient: {
        1: {
          start: {
            default: 'blue.500',
            _dark: 'blue.200',
          },
          end: {
            default: 'pink.500',
            _dark: 'pink.300',
          },
        },
        2: {
          start: {
            default: 'blue.500',
            _dark: 'blue.200',
          },
          end: {
            default: 'pink.600',
            _dark: 'pink.200',
          },
        },
        3: {
          start: {
            default: '#2100F7',
            _dark: '#A194F8',
          },
          end: {
            default: '#AD00D4',
            _dark: '#C483D2',
          },
        },
      },
    },
    success: { default: 'green.600', _dark: 'green.200' },
    danger: { default: 'red.600', _dark: 'red.200' },
    warning: { default: 'orange.600', _dark: 'orange.200' },
    goal: {
      primary: {
        default: 'pink.800',
        _dark: 'pink.100',
      },
      secondary: {
        default: 'pink.700',
        _dark: 'pink.200',
      },
      bg: {
        default: 'purple.50',
        _dark: 'purple.900',
      },
    },
  },
};
