import { Capacitor } from '@capacitor/core';
import { LocalNotifications } from '@capacitor/local-notifications';
import { PushNotifications } from '@capacitor/push-notifications';
import type { Task, TaskType } from '@src/api';
import { appStoreUrl, playStoreUrl } from '@src/constants';
import { AppStore } from '@src/stores';

export const generateGoalText = (goalEntryDayCount: number) =>
  goalEntryDayCount > 1 ? `every ${goalEntryDayCount} days` : 'every day';

export const generateStoreText = () => {
  if (Capacitor.getPlatform() === 'ios') {
    return 'the App Store';
  }
  if (Capacitor.getPlatform() === 'android') {
    return 'Google Play';
  }
  return undefined;
};

export const getStoreUrl = () => {
  if (Capacitor.getPlatform() === 'ios') {
    return appStoreUrl;
  }
  if (Capacitor.getPlatform() === 'android') {
    return playStoreUrl;
  }
  return undefined;
};

export const getStoreRatingUrl = () => {
  if (Capacitor.getPlatform() === 'ios') {
    return `${appStoreUrl}?action=write-review`;
  }
  if (Capacitor.getPlatform() === 'android') {
    return playStoreUrl;
  }
  return undefined;
};

export { getThinkingTrapIcon } from './thinkingTrapIcons';

export { default as facebookInitScript } from './facebookInitScript';

export const digestMessage = async (message: string) => {
  const msgUint8 = new TextEncoder().encode(message); // encode as (utf-8) Uint8Array
  const hashBuffer = await crypto.subtle.digest('SHA-256', msgUint8); // hash the message
  const hashArray = Array.from(new Uint8Array(hashBuffer)); // convert buffer to byte array
  const hashHex = hashArray
    .map((b) => b.toString(16).padStart(2, '0'))
    .join(''); // convert bytes to hex string
  return hashHex;
};

export const checkPushNotificationPermissions = async () => {
  if (
    Capacitor.getPlatform() === 'ios' &&
    Capacitor.isPluginAvailable('PushNotifications')
  ) {
    const { receive } = await PushNotifications.checkPermissions();
    return receive;
  }

  if (
    Capacitor.getPlatform() === 'android' &&
    Capacitor.isPluginAvailable('LocalNotifications')
  ) {
    const { display } = await LocalNotifications.checkPermissions();
    return display;
  }

  return;
};

export const requestPushNotificationPermissions = async () => {
  const handlePlatforms = async () => {
    if (
      Capacitor.getPlatform() === 'ios' &&
      Capacitor.isPluginAvailable('PushNotifications')
    ) {
      const { receive } = await PushNotifications.requestPermissions();
      if (receive === 'granted') {
        PushNotifications.register();
      }
      return receive;
    }

    if (
      Capacitor.getPlatform() === 'android' &&
      Capacitor.isPluginAvailable('LocalNotifications')
    ) {
      const { display } = await LocalNotifications.requestPermissions();
      return display;
    }

    return;
  };

  AppStore.update((s) => {
    s.isNativeDialog = true;
  });

  const data = await handlePlatforms();

  setTimeout(() => {
    AppStore.update((s) => {
      s.isNativeDialog = false;
    });
  }, 1000);

  return data;
};

export const organizeTasksByType = (tasks: Task[] = []) => {
  return tasks.reduce(
    (accumulator, task) => ({
      ...accumulator,
      [task.type]: true,
    }),
    {} as Record<TaskType, boolean>,
  );
};

export const determineMobileOperatingSystem = () => {
  const userAgent =
    navigator.userAgent || navigator.vendor || (window as any).opera;

  // Windows Phone must come first because its UA also contains "Android"
  if (/windows phone/i.test(userAgent)) {
    return 'other';
  }

  if (/android/i.test(userAgent)) {
    return 'android';
  }

  if (
    [
      'iPad Simulator',
      'iPhone Simulator',
      'iPod Simulator',
      'iPad',
      'iPhone',
      'iPod',
    ].includes(navigator.platform) ||
    (navigator.userAgent.includes('Mac') && 'ontouchend' in document)
  ) {
    return 'ios';
  }

  return 'other';
};
