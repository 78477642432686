import { Box, type BoxProps, Image, useColorMode } from '@chakra-ui/react';
import logomark from '@src/assets/logomark.svg';
import logomarkDark from '@src/assets/logomarkDark.svg';
import { AppStore } from '@src/stores';
import type { FC } from 'react';

const PrivacyCover: FC<BoxProps> = ({ ...props }) => {
  const isAppActive = AppStore.useState((s) => s.isAppActive);
  const isNativeDialog = AppStore.useState((s) => s.isNativeDialog);

  const isShown = !isAppActive && !isNativeDialog;
  const { colorMode } = useColorMode();

  return (
    <Box
      position="fixed"
      zIndex={1}
      height="100vh"
      width="100vw"
      bgColor="neutral.bg.0"
      display={isShown ? 'flex' : 'none'}
      justifyContent="center"
      alignItems="center"
      {...props}
    >
      <Image
        src={colorMode === 'dark' ? logomarkDark : logomark}
        alt=""
        width="60px"
        mb="10vh"
      />
    </Box>
  );
};

export default PrivacyCover;
