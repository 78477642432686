import { App, type BackButtonListener } from '@capacitor/app';

let callbacks: (BackButtonListener | undefined)[] = [];

export const addBackListener = (callback: BackButtonListener) => {
  callbacks = [...callbacks, callback];
};

export const removeBackListener = (callback: BackButtonListener) => {
  callbacks = callbacks.filter((n) => n !== callback);
};

export const initBackListener = () => {
  App.addListener('backButton', (options) => {
    const callback = callbacks[callbacks.length - 1];
    if (callback) {
      callback(options);
    }
  });

  addBackListener(({ canGoBack }) => {
    if (canGoBack) {
      window.history.back();
    } else {
      App.exitApp();
    }
  });
};
