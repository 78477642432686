import { Box, Image, Link, Text, useBreakpointValue } from '@chakra-ui/react';
import Card from '@src/components/Card';
import { ChevronLeft, CircleFilled, CircleUnfilled } from '@src/icons';
import { useMeasure } from '@uidotdev/usehooks';
import Carousel from 'nuka-carousel';
import type { FC } from 'react';
import quoteGradient from './quoteGradient.svg';

interface Quote {
  text: string;
  author: string;
}

const getLongestQuoteText = (quotes: Quote[]) =>
  quotes.reduce(
    (accumulator, { text }) =>
      accumulator.length > text.length ? accumulator : text,
    '',
  );

const QuotesCarousel: FC<{ quotes: Quote[] }> = ({ quotes }) => {
  const [ref, { width }] = useMeasure();
  const [dotsRef, { height: dotsHeight }] = useMeasure();
  const slidesToShowFullWidthDefault = useBreakpointValue(
    {
      base: Math.max(1, (width ?? 100) * 0.003076923077), // 1.2 at 390
      md: Math.max(1, (width ?? 100) * 0.002430555556), // 3.5 at 1440
    },
    { ssr: false },
  );

  const longestQuoteText = getLongestQuoteText(quotes);

  return (
    <Box
      position="relative"
      pb={dotsHeight ?? 0}
      zIndex={0} // Prevent dots from going above navbar
      ref={ref}
    >
      {width && (
        <Carousel
          slidesToShow={slidesToShowFullWidthDefault}
          cellAlign="center"
          wrapAround
          enableKeyboardControls
          renderCenterRightControls={() => null}
          renderCenterLeftControls={() => null}
          renderBottomCenterControls={({
            nextSlide,
            previousSlide,
            currentSlide,
            pagingDotsIndices,
          }) => {
            const styles = {
              boxSize: '8px',
              color: 'var(--chakra-colors-action-tertiary)',
            };
            const iconStyles = {
              boxSize: '24px',
              color: 'action.tertiary',
            };
            return (
              <Box transform="translateY(100%)" pt="hecto" ref={dotsRef}>
                <Link as="button" onClick={previousSlide}>
                  <ChevronLeft {...iconStyles} />
                </Link>
                <Box display="inline-flex" gap="centi" mx="kilo" aria-hidden>
                  {pagingDotsIndices.map((index) =>
                    index !== currentSlide ? (
                      <CircleUnfilled key={index} {...styles} />
                    ) : (
                      <CircleFilled key={index} {...styles} />
                    ),
                  )}
                </Box>
                <Link as="button" onClick={nextSlide}>
                  <ChevronLeft {...iconStyles} transform="rotate(180deg)" />
                </Link>
              </Box>
            );
          }}
        >
          {quotes.map(({ text, author }, index) => (
            <Box
              key={index}
              px={{
                base: 'calc(var(--chakra-space-deci) / 2)',
                md: 'calc(var(--chakra-space-hecto) / 2)',
              }}
              py="deci" // Prevent box shadow from getting cut off
            >
              <Card
                py="hecto"
                px={{ base: 'hecto', md: 'kilo' }}
                bg="brand.bg.10"
                border="none"
                borderRadius="24px"
                boxShadow="0px 2px 8px 2px rgba(0, 0, 51, 0.1)"
                textStyle="popDuck"
                opacity={0.5}
                sx={{
                  '.slide-current &': {
                    opacity: 1,
                  },
                }}
              >
                <Box display="flex" flexDirection="column">
                  <Box mb="regular">
                    <Image src={quoteGradient} alt="Quote mark" />
                  </Box>
                  <Box position="relative" flex={1}>
                    <Text visibility="hidden">{longestQuoteText}</Text>
                    <Text position="absolute" top={0}>
                      {text}
                    </Text>
                  </Box>
                  <Box textStyle="duck" textAlign="right" mt="deci">
                    {author}
                  </Box>
                </Box>
              </Card>
            </Box>
          ))}
        </Carousel>
      )}
    </Box>
  );
};

export default QuotesCarousel;
