import { Capacitor } from '@capacitor/core';
import { Device } from '@capacitor/device';
import { observable, observe, unobserve } from '@nx-js/observer-util';
import { useQueryClient } from '@tanstack/react-query';
import ky from 'ky';
import { usePostHog } from 'posthog-js/react';
import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { instance as apiInstance } from './api';
import { backendUrl } from './constants';
import * as storage from './storage';
import { PinStore } from './stores';
import { currentVersions } from './versioning';

type Tokens =
  | {
      accessToken: string;
      refreshToken: string;
      accessTokenExpirationTime: number; // Unix time in seconds
    }
  | undefined;

interface State {
  tokens?: Tokens;
}

const storageKey = 'identity';

const state = observable<State>({ tokens: undefined });

export const runMigration = async () => {
  const identityDataString = window.localStorage.getItem(storageKey);
  if (identityDataString == null) return;
  const identityData = JSON.parse(identityDataString);
  await storage.set(storageKey, identityData);
  window.localStorage.removeItem(storageKey);
};

const init = async (): Promise<void> => {
  await runMigration();
  state.tokens = (await storage.get<State>(storageKey))?.tokens;

  observe(() => {
    storage.set(storageKey, state);
  });
};

// Enable sign out across tabs
window.addEventListener('storage', (event) => {
  if (event.key === `CapacitorStorage.${storageKey}`) {
    const newValue =
      event.newValue == null ? undefined : JSON.parse(event.newValue);
    state.tokens = newValue?.tokens;
  }
});

export const useInit = () => {
  const [isInit, setIsInit] = useState(false);

  useEffect(() => {
    init().then(() => {
      setIsInit(true);
    });
  }, []);

  return isInit;
};

export const useTokens = (): Tokens => {
  const [tokens, setTokens] = useState<Tokens>(state.tokens);

  useEffect(() => {
    const setter = observe(() => setTokens(state.tokens));

    return () => {
      unobserve(setter);
    };
  });

  return tokens;
};

// Import once in project. Take actions when tokens are cleared.
export const useOnSignOut = () => {
  const tokens = useTokens();
  const [prevTokens, setPrevTokens] = useState<Tokens>();
  const queryClient = useQueryClient(); // Using the queryClient instance from index doesn’t seem to work.
  const navigate = useNavigate();
  const postHog = usePostHog();

  useEffect(() => {
    if (tokens !== prevTokens) {
      if (!tokens && prevTokens) {
        PinStore.update((s) => {
          s.hashedPin = undefined;
        });
        queryClient.removeQueries(); // Must be remove, not reset, otherwise it will fill in the old cached value
        postHog?.reset();
        navigate('/');
      }

      setPrevTokens(tokens);
    }
  }, [tokens, navigate, prevTokens, queryClient, postHog]);
};

const clearTokens = () => {
  state.tokens = undefined;
};

const instance = ky.create({
  prefixUrl: backendUrl,
  hooks: {
    beforeRequest: [
      async (request) => {
        request.headers.set('frontend-app-version', currentVersions.version);
      },
    ],
    beforeError: [
      async (error) => {
        if (error.response.status === 401) {
          clearTokens();
        }
        return error;
      },
    ],
  },
});

export const signOut = async () => {
  const { identifier } = await Device.getId();
  try {
    await apiInstance
      .post('users/signOut', {
        json: { deviceId: identifier },
      })
      .json();
  } finally {
    clearTokens();
  }
};

export const deleteMe = async () => {
  await apiInstance.delete('users/me').json();
  clearTokens();
};

// Ensure multiple refresh token requests don't go out at the same time.
let activeRefreshTokenRequest: Promise<Tokens> | undefined;
export const refreshToken = (): Promise<Tokens> => {
  if (activeRefreshTokenRequest) {
    return activeRefreshTokenRequest;
  }

  activeRefreshTokenRequest = instance
    .post('users/refreshToken', {
      json: { refreshToken: state.tokens?.refreshToken },
    })
    .json<Tokens>()
    .finally(() => {
      activeRefreshTokenRequest = undefined;
    });
  return activeRefreshTokenRequest;
};

export const getTokens = async (): Promise<Tokens> => {
  // If no tokens are present, assume they are not needed and the user is using the unauthenticated app.
  if (!state.tokens) return;

  const leewayInSeconds = 40;
  if (
    (state.tokens?.accessTokenExpirationTime ?? 0) - leewayInSeconds >
    Date.now() / 1000
  ) {
    return state.tokens;
  }

  const data = await refreshToken();

  state.tokens = data;

  return state.tokens;
};

// Should only be used by App/Play store reviewers
export const signInPassword = async (
  email: string,
  password: string,
): Promise<void> => {
  const tokens = await getTokens();
  const data = await instance
    .post('users/signInPassword', {
      json: {
        email,
        password,
      },
      headers: {
        ...(tokens
          ? {
              Authorization: `Bearer ${tokens.accessToken}`,
            }
          : {}),
      },
    })
    .json<{ auth: Tokens }>();

  state.tokens = data.auth;
};

export const signInEmailStart = async (email: string): Promise<void> => {
  const path = Capacitor.isNativePlatform()
    ? 'complete-email-sign-in-native'
    : 'complete-email-sign-in';

  await instance.post('users/signInEmailStart', {
    json: {
      email,
      continueUrl: `${
        import.meta.env.PROD
          ? 'https://becomeunstuck.app'
          : 'http://localhost:3334'
      }/${path}`,
    },
  });
};

export const signInEmailComplete = async (
  email: string,
  oobCode: string,
): Promise<void> => {
  const tokens = await getTokens();

  const data = await instance
    .post('users/signInEmailComplete', {
      json: {
        email,
        oobCode,
      },
      headers: tokens
        ? {
            Authorization: `Bearer ${tokens.accessToken}`,
          }
        : {},
    })
    .json<{ auth: Tokens }>();

  state.tokens = data.auth;
};

export const signUpAnonymously = async (): Promise<void> => {
  const data = await instance.post('users/signUp').json<{ auth: Tokens }>();

  state.tokens = data.auth;
};
