import { Capacitor, registerPlugin } from '@capacitor/core';
import { facebookInitScript } from './utils';

const pixelId = '563204632147191';
const appId = '381138200696780';

const shouldUsePixel = import.meta.env.VITE_DISABLE_PIXEL !== 'true';

export const initPixel = () => {
  if (shouldUsePixel) {
    registerPlugin('FacebookSdk');

    facebookInitScript();
    // window.fbq should be there. If the FB scripts are not fetched yet, the function calls get added to a queue.

    window.fbq('set', 'autoConfig', false, pixelId); // Enable Manual Only mode.
    window.fbq('init', pixelId);

    // https://developers.facebook.com/docs/app-events/hybrid-app-events
    if (Capacitor.isNativePlatform()) {
      window.fbq('set', 'mobileBridge', pixelId, appId);
    }

    window.fbq('track', 'PageView');
  } else {
    window.fbq = () => {
      // no-op
    };
  }
};

export const setExternalId = (externalId: string) => {
  if (shouldUsePixel && window.fbq) {
    window.fbq('init', pixelId, { external_id: externalId });
    window.fbq('track', 'ViewContent'); // Send a tracking event just to make sure externalId is propagated. Not sure if this is necessary.
  }
};

export const logFacebook = (
  eventName: string,
  isCustom: boolean,
  params: Record<string, any> = {},
) => {
  if (shouldUsePixel && window.fbq) {
    window.fbq(isCustom ? 'trackCustom' : 'track', eventName, params);
    return;
  }
};
