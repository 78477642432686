import { Box, Button, Text } from '@chakra-ui/react';
import Page from '@src/components/Page';
import type { FC } from 'react';

const ScheduledMaintenance: FC = () => {
  return (
    <Page
      content={
        <Box textAlign="center">
          <Text mt="20vh" mb="regular">
            Unstuck is undergoing scheduled maintenance. We’ll be back online in
            about 10 minutes.
          </Text>
          <Button
            onClick={() => {
              window.location.reload();
            }}
          >
            Reload
          </Button>
        </Box>
      }
    />
  );
};

export default ScheduledMaintenance;
