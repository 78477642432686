export default {
  pink: {
    '50': '#ECD8EC',
    '100': '#E6AFE6',
    '200': '#D887D9',
    '300': '#D887D9',
    '400': '#EB4DEB',
    '500': '#E41FE4',
    '600': '#CC00CC',
    '700': '#990099',
    '800': '#660066',
    '900': '#330033',
  },
  blue: {
    '30': '#F7F7FF',
    '50': '#E5E5FF',
    '100': '#B8B8FF',
    '200': '#8A8AFF',
    '300': '#5C5CFF',
    '400': '#2E2EFF',
    '500': '#0000FF',
    '600': '#0000CC',
    '700': '#000099',
    '800': '#000066',
    '900': '#000033',
  },
  gray: {
    '50': '#F2F2F2',
    '100': '#DBDBDB',
    '200': '#C4C4C4',
    '300': '#ADADAD',
    '400': '#969696',
    '500': '#808080',
    '600': '#666666',
    '700': '#4D4D4D',
    '800': '#333333',
    '900': '#1A1A1A',
  },
  red: {
    '50': '#F3DDDD',
    '100': '#F3B9B9',
    '200': '#E29A9A',
    '300': '#E17777',
    '400': '#F24848',
    '500': '#ED2B2B',
    '600': '#BE0E0E',
    '700': '#8E0B0B',
    '800': '#5F0707',
    '900': '#2F0404',
  },
  orange: {
    '50': '#FBF1E1',
    '100': '#EFD8B5',
    '200': '#DCBA86',
    '300': '#E8B15E',
    '400': '#EDA334',
    '500': '#E49012',
    '600': '#C1780B',
    '700': '#915A08',
    '800': '#613C05',
    '900': '#301E03',
  },
  green: {
    '50': '#E9F9F0',
    '100': '#E9F9F0',
    '200': '#E9F9F0',
    '300': '#69B88D',
    '400': '#38B973',
    '500': '#37B16E',
    '600': '#23A960',
    '700': '#1A7F48',
    '800': '#125430',
    '900': '#092A18',
  },
  purple: {
    '50': '#F9F3FF',
    '900': '#2E1B37',
  },
  sky: {
    '500': '#5E98F0',
  },
  earth: {
    '500': '#DA9D43',
  },
  white: '#FFFFFF',
};
