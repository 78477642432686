import { Box, Image } from '@chakra-ui/react';
import stars5 from '@src/assets/stars5.svg';
import type { FC } from 'react';

const PullQuote: FC<{
  text: string;
  author: string;
}> = ({ text, author }) => {
  return (
    <Box>
      <Image src={stars5} alt="5 stars" display="inline-block" />
      <Box
        textStyle={{ base: 'popGoose', lg: 'popSwan' }}
        mt="regular"
        mb="deci"
      >
        {text}
      </Box>
      <Box textStyle={{ base: 'duck', lg: 'goose' }}>{author}</Box>
    </Box>
  );
};

export default PullQuote;
