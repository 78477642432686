import {
  Box,
  type BoxProps,
  Button,
  Divider,
  Flex,
  Heading,
  Progress,
  chakra,
} from '@chakra-ui/react';
import { ChevronLeft } from '@src/icons';
import type { FC, ReactElement } from 'react';

export interface Props extends BoxProps {
  onBack?: () => void;
  headingText?: string;
  progressPercent?: number;
  isProgressGradient?: boolean;
  isInProgress?: boolean;
  renderRightElement?: () => ReactElement;
}

const TopMobileNavBar: FC<Props> = ({
  onBack,
  headingText = ' ',
  progressPercent,
  isProgressGradient,
  renderRightElement,
  isInProgress,
  ...props
}) => {
  return (
    <Box
      as="nav"
      position="sticky"
      top={0}
      zIndex={4}
      bg="neutral.bg.0"
      pt="env(safe-area-inset-top)"
      pl="env(safe-area-inset-left)"
      pr="env(safe-area-inset-right)"
      {...props}
    >
      <Flex width="100%" align="center">
        <Box flex={1}>
          <Button
            visibility={onBack ? 'visible' : 'hidden'}
            variant="link"
            padding={4}
            onClick={onBack}
            aria-label="Back"
            color="action.tertiary"
          >
            <ChevronLeft boxSize={22} aria-hidden />
          </Button>
        </Box>
        <Heading
          as="h1"
          textStyle="popDuck"
          noOfLines={1}
          textAlign="center"
          flexShrink={1}
          color="neutral.3"
          display="flex"
          alignItems="center"
        >
          <Box as="span">
            {headingText}
            {isInProgress && (
              <chakra.span textStyle="popHummingbird" color="warning">
                {' '}
                (in progress)
              </chakra.span>
            )}
          </Box>
        </Heading>
        <Box flex={1} textAlign="right">
          {renderRightElement?.()}
        </Box>
      </Flex>
      {progressPercent === undefined ? (
        <Divider />
      ) : (
        <Progress
          value={progressPercent}
          size="xs"
          variant={isProgressGradient ? 'gradient' : undefined}
        />
      )}
    </Box>
  );
};

export default TopMobileNavBar;
