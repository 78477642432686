import { App } from '@capacitor/app';
import dayjs from 'dayjs';
import { Store } from 'pullstate';
import * as storage from './storage';

export const AppStore = new Store<{
  showPageLoading: boolean;
  isAppActive: boolean;
  isNativeDialog: boolean;
  referrerId?: string;
}>({
  showPageLoading: false,
  isAppActive: true,
  isNativeDialog: false,
});

App.addListener('appStateChange', ({ isActive }) => {
  AppStore.update((s) => {
    s.isAppActive = isActive;
  });
});

const hashedPinStorageKey = 'hashedPin';
export const PinStore = new Store<{
  isAppActive: boolean;
  isInitialized: boolean;
  hashedPin?: string;
  lastActivityWhileUnlockedTimestamp: Date | null;
  isVerified: boolean;
}>({
  isAppActive: true,
  isInitialized: false,
  hashedPin: undefined,
  lastActivityWhileUnlockedTimestamp: null,
  isVerified: false,
});

PinStore.subscribe(
  (s) => s.hashedPin,
  (hashedPin) => {
    if (hashedPin) {
      storage.set(hashedPinStorageKey, hashedPin);
    } else {
      storage.remove(hashedPinStorageKey);
    }
  },
);

AppStore.subscribe(
  (s) => s.isAppActive,
  (isAppActive) => {
    PinStore.update((s) => {
      s.isAppActive = isAppActive;
    });
  },
);

export const initializePinStore = async () => {
  const hashedPin = await storage.get<undefined | string>(hashedPinStorageKey);

  PinStore.update((s) => {
    s.isInitialized = true;
    s.hashedPin = hashedPin;
  });

  PinStore.createReaction(
    (s) => s.isAppActive,
    (isAppActive, draft) => {
      if (!isAppActive && draft.isVerified) {
        draft.lastActivityWhileUnlockedTimestamp = new Date();
      }

      const minutesSinceLastActivityWhileUnlockedTimestamp = dayjs().diff(
        dayjs(draft.lastActivityWhileUnlockedTimestamp),
        'minute',
        true,
      );

      const minutesBeforeLock = 2;

      if (
        isAppActive &&
        minutesSinceLastActivityWhileUnlockedTimestamp > minutesBeforeLock
      ) {
        draft.isVerified = false;
      }
    },
  );

  PinStore.createReaction(
    (s) => s.hashedPin,
    (hashedPin, draft) => {
      if (!hashedPin) {
        draft.isVerified = false;
        draft.lastActivityWhileUnlockedTimestamp = null;
      } else {
        draft.isVerified = true;
      }
    },
  );
};
