import { Box, Divider, Flex, Image, Link, Text } from '@chakra-ui/react';
import Socials from '@src/components/Socials';
import type { FC } from 'react';
import { Link as RouterLink } from 'react-router-dom';
import { landingPaddingX } from '.';
import logo from './logoBlack.svg';

const Footer: FC = () => {
  return (
    <Box
      as="footer"
      px={landingPaddingX}
      py="kilo"
      bg="blue.30"
      textAlign={{ base: 'center', md: 'left' }}
    >
      <Socials />

      <Divider
        display={{ base: 'block', md: 'none' }}
        my="hecto"
        borderColor="blue.50"
      />

      <Flex
        flexDirection={{ base: 'column', md: 'row-reverse' }}
        justifyContent="space-between"
        alignItems={{ base: 'center', md: 'end' }}
        gap="hecto"
        mt="hecto"
      >
        <Box whiteSpace="nowrap">
          <Link
            as={RouterLink}
            to="/terms-of-service"
            color="gray.900"
            textStyle={{ base: 'popPuffin', lg: 'popDuck' }}
            mr="regular"
          >
            Terms of Service
          </Link>
          <Link
            as={RouterLink}
            to="/privacy-policy"
            color="gray.900"
            textStyle={{ base: 'popPuffin', lg: 'popDuck' }}
          >
            Privacy Policy
          </Link>
        </Box>
        <Box>
          <Box textStyle={{ base: 'popPuffin', lg: 'popDuck' }}>
            <Image
              src={logo}
              alt="Unstuck"
              display="inline-block"
              mr={{ base: 0, md: 'deci' }}
              position="relative"
              top="8px"
            />
            <Text
              display={{ base: 'block', md: 'inline' }}
              color="gray.700"
              whiteSpace="nowrap"
              mt="centi"
            >
              Overcome anxiety with CBT journaling
            </Text>
          </Box>
          <Text
            color="gray.500"
            textStyle={{ base: 'sparrow', lg: 'puffin' }}
            mt="deci"
          >
            © 2023 Woo Developments LLC
          </Text>
        </Box>
      </Flex>
    </Box>
  );
};

export default Footer;
