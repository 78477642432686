import { createSyncStoragePersister } from '@tanstack/query-sync-storage-persister';
import { QueryClient } from '@tanstack/react-query';

// Keep here instead of in the entry point. exports in the entry point might be causing an issue in dev.
// https://github.com/vitejs/vite/discussions/4583

const maxAge = 1000 * 60 * 60 * 24 * 14; // 14 days

export const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      retry: false,
      // By default, react-query will pause loading if you are not connected to the internet. You may want to disable that if you want to develop offline.
      networkMode:
        import.meta.env.VITE_OFFLINE_MODE === 'true' ? 'always' : undefined,
      gcTime: maxAge, // For persister
    },
    mutations: {
      networkMode:
        import.meta.env.VITE_OFFLINE_MODE === 'true' ? 'always' : undefined,
    },
  },
});

export const persister = createSyncStoragePersister({
  storage: window.localStorage,
});
