import { Capacitor } from '@capacitor/core';
import { getMe, updateMe } from '@src/api';
import { AppStore } from '@src/stores';
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import dayjs from 'dayjs';
import Cookies from 'js-cookie';
import { usePostHog } from 'posthog-js/react';
import { useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { referrersById } from 'shared';
import * as identityService from '../identityService';

const referrerIdCookieKey = 'referrerId';

export const useSetReferrerCookie = () => {
  const tokens = identityService.useTokens();
  const params = useParams();

  const referrerIdFromCookie = Cookies.get(referrerIdCookieKey);
  const referrerIdFromSplat = params['*']?.toLowerCase();

  const referrerId = referrerIdFromSplat || referrerIdFromCookie;

  useEffect(() => {
    if (
      !tokens &&
      Capacitor.getPlatform() === 'web' &&
      referrerId &&
      referrersById[referrerId]
    ) {
      Cookies.set(referrerIdCookieKey, referrerId, {
        path: '/',
        sameSite: 'strict',
        expires: dayjs().add(3, 'month').toDate(),
      });
      AppStore.update((s) => {
        s.referrerId = referrerId;
      });
    }
  }, [tokens, referrerId]);
};

export const useSaveReferrerIdDesktop = (canMakeRequests: boolean) => {
  const posthog = usePostHog();
  const referrerId = AppStore.useState((s) => s.referrerId);
  const queryClient = useQueryClient();
  const meQuery = useQuery({
    queryKey: ['me'],
    queryFn: getMe,
    enabled: canMakeRequests,
  });
  const meMutation = useMutation({
    mutationFn: updateMe,
    onSuccess: () => {
      queryClient.invalidateQueries({
        queryKey: ['me'],
      });
    },
  });
  const mutate = meMutation.mutate;
  const existingReferrerId =
    meQuery.data?.user.referrerId ?? meMutation.variables;

  useEffect(() => {
    if (
      canMakeRequests &&
      Capacitor.getPlatform() === 'web' &&
      referrerId &&
      referrersById[referrerId] &&
      !existingReferrerId
    ) {
      mutate({ referrerId: referrerId });
      posthog?.capture('ReferrerId saved', { referrerId });
    }
  }, [canMakeRequests, mutate, existingReferrerId, referrerId, posthog]);
};
