export default {
  milli: '0.25rem',
  centi: '0.5rem',
  deci: '1rem',
  regular: '1.5rem',
  deka: '2rem',
  hecto: '2.5rem',
  kilo: '3rem',
  mega: '4rem',
  giga: '5rem',
  tera: '7.5rem',
};
