// Dashed to hack around Chakra bug, which should be fixed at some point:
// https://github.com/chakra-ui/chakra-ui/discussions/3612#discussioncomment-1628251

const baseTextStyles = {
  dragon: {
    'font-size': '3.653rem',
    'line-height': '4.375rem',
  },
  phoenix: {
    'font-size': '2.566rem',
    'line-height': '3.125rem',
  },
  ostrich: {
    'font-size': '2.027rem',
    'line-height': '3.0625rem',
  },
  emu: {
    'font-size': '1.802rem',
    'line-height': '2.75rem',
  },
  flamingo: {
    'font-size': '1.602rem',
    'line-height': '2.25rem',
  },
  heron: {
    'font-size': '1.42375rem',
    'line-height': '2rem',
  },
  swan: {
    'font-size': '1.266rem',
    'line-height': '1.75rem',
  },
  goose: {
    'font-size': '1.125rem',
    'line-height': '1.75rem',
  },
  duck: {
    'font-size': '1rem',
    'line-height': '1.75rem',
  },
  puffin: {
    'font-size': '0.88875rem',
    'line-height': '1.5rem',
  },
  sparrow: {
    'font-size': '0.79rem',
    'line-height': '1.25rem',
  },
  hummingbird: {
    'font-size': '0.7025rem',
    'line-height': '1.125rem',
  },
  beatle: {
    'font-size': '0.624rem',
    'line-height': '1rem',
  },
  fly: {
    'font-size': '0.555rem',
    'line-height': '1rem',
  },
};

// lodash.mapValues would be better, but avoiding it to keep initial bundle size down.
const openSansTextStyles = Object.entries(baseTextStyles).reduce(
  (accumulator, [key, value]) => ({
    ...accumulator,
    [key]: {
      ...value,
      'font-family': 'Open Sans, Helvetica, Arial, sans-serif',
    },
  }),
  {} as typeof baseTextStyles,
);

const popTextStyles = {
  popDragon: baseTextStyles.dragon,
  popPhoenix: baseTextStyles.phoenix,
  popOstrich: baseTextStyles.ostrich,
  popEmu: baseTextStyles.emu,
  popFlamingo: baseTextStyles.flamingo,
  popHeron: baseTextStyles.heron,
  popSwan: baseTextStyles.swan,
  popGoose: baseTextStyles.goose,
  popDuck: baseTextStyles.duck,
  popPuffin: baseTextStyles.puffin,
  popSparrow: baseTextStyles.sparrow,
  popHummingbird: baseTextStyles.hummingbird,
  popBeatle: baseTextStyles.beatle,
  popFly: baseTextStyles.fly,
};

export const poppinsBaseStyles = {
  'font-family': 'Poppins, Helvetica, Arial, sans-serif',
  'font-weight': '500',
};

const poppinsTextStyles = Object.entries(popTextStyles).reduce(
  (accumulator, [key, value]) => ({
    ...accumulator,
    [key]: {
      ...value,
      ...poppinsBaseStyles,
    },
  }),
  {} as typeof popTextStyles,
);

export default {
  ...openSansTextStyles,
  ...poppinsTextStyles,
};
