import { DarkMode } from '@aparajita/capacitor-dark-mode';
import { App as CapacitorApp, type URLOpenListenerEvent } from '@capacitor/app';
import { Capacitor } from '@capacitor/core';
import { Device } from '@capacitor/device';
import { Keyboard } from '@capacitor/keyboard';
import { PushNotifications } from '@capacitor/push-notifications';
import { Box, ChakraProvider, ColorModeScript } from '@chakra-ui/react';
import { Purchases } from '@revenuecat/purchases-capacitor';
import {
  ErrorBoundary as RollbarErrorBoundary,
  Provider as RollbarProvider,
  useRollbar,
  useRollbarPerson,
} from '@rollbar/react';
import { getMe, getMeMetadata, saveDevice } from '@src/api';
import { rcPublicKeyAndroid, rcPublicKeyApple } from '@src/constants';
import { useSaveReferrerIdDesktop, useSetReferrerCookie } from '@src/referrer';
import { PinStore } from '@src/stores';
import { useQuery } from '@tanstack/react-query';
import { PersistQueryClientProvider } from '@tanstack/react-query-persist-client';
import { AppsFlyer } from 'appsflyer-capacitor-plugin';
import dayjs from 'dayjs';
import { TimeoutError } from 'ky';
import { isEqual } from 'lodash-es';
import { PostHogProvider, usePostHog } from 'posthog-js/react';
import { StrictMode, Suspense, useEffect, useState } from 'react';
import { createRoot } from 'react-dom/client';
import { Helmet, HelmetProvider } from 'react-helmet-async';
import {
  Outlet,
  RouterProvider,
  createBrowserRouter,
  useLocation,
  useNavigate,
} from 'react-router-dom';
import { initBackListener } from './backListener';
import PinModal from './components/PinModal';
import PrivacyCover from './components/PrivacyCover';
import { initPixel, setExternalId } from './facebookLogging';
import {
  useColorModeManage,
  useConnectionToasts,
  useDeleteEmptyEntry,
  useInitAppsFlyer,
} from './hooks';
import * as identityService from './identityService';
import { AppTracking } from './plugins/app-tracking';
import { persister, queryClient } from './queryClient';
import { initializePinStore } from './stores';
import theme from './theme';
import {
  checkMinimumVersionAndUpdateMobile,
  currentVersions,
  initializeUpdating,
} from './versioning';
import {
  About,
  Appearance,
  AssessmentResults,
  CompleteEmailSignIn,
  Contact,
  Dashboard,
  Entries,
  EntrySelector,
  EntryView,
  ErrorView,
  Exposures,
  ExposuresList,
  FreeRequest,
  Goals,
  HowToAccount,
  Insights,
  JournalCalendarView,
  Landing,
  LearnItem,
  LearnList,
  Notifications,
  Onboarding,
  PhqAdsQuestionnaire,
  PinSetup,
  PrivacyPolicy,
  Profile,
  ScheduledMaintenance,
  SignIn,
  Subscriptions,
  Summary,
  TaskView,
  TermsOfService,
  ThinkingTrapsView,
} from './viewImporter';

initBackListener();
initPixel();
initializePinStore();

const Root = () => {
  const isScheduledMaintenance =
    dayjs().isAfter(dayjs('2024-11-17T16:59:00Z')) &&
    dayjs().isBefore(dayjs('2024-11-17T17:06:00Z'));

  const rollbar = useRollbar();
  const location = useLocation();
  const navigate = useNavigate();
  const posthog = usePostHog();
  const tokens = identityService.useTokens();
  useConnectionToasts();
  const canMakeRequests =
    !!tokens &&
    !isScheduledMaintenance &&
    location.pathname !== '/complete-email-sign-in-native' &&
    location.pathname !== '/complete-email-sign-in';
  const meQuery = useQuery({
    queryKey: ['me'],
    queryFn: getMe,
    enabled: canMakeRequests,
  });
  const meMetadataQuery = useQuery({
    queryKey: ['me', 'metadata'],
    queryFn: getMeMetadata,
    enabled: canMakeRequests,
  });
  const [savedUserId, setSavedUserId] = useState<string>();
  const [savedMeMetadata, setSavedMeMetadata] = useState<any>();

  identityService.useOnSignOut();

  useEffect(() => {
    const idHashed = meQuery.data?.user?.idHashed;
    if (Capacitor.getPlatform() === 'web' && idHashed) {
      setExternalId(idHashed);
    }
  }, [meQuery.data?.user?.idHashed]);

  useRollbarPerson(meQuery.data?.user ? { id: meQuery.data.user.id } : {});

  useEffect(() => {
    const id = meQuery.data?.user?.id;
    // isPluginAvailable doesn’t work here
    if (id && Capacitor.isNativePlatform()) {
      Purchases.configure({
        apiKey:
          Capacitor.getPlatform() === 'ios'
            ? rcPublicKeyApple
            : rcPublicKeyAndroid,
        appUserID: id,
      });

      Purchases.collectDeviceIdentifiers();

      if (Capacitor.getPlatform() === 'ios') {
        AppTracking.getData().then((data) => {
          if (data.fbAnonId) {
            Purchases.setFBAnonymousID({ fbAnonymousID: data.fbAnonId });
          }
        });
        Purchases.enableAdServicesAttributionTokenCollection();
      }

      AppsFlyer.getAppsFlyerUID().then((res) => {
        Purchases.setAppsflyerID({ appsflyerID: res.uid });
      });
    }
  }, [meQuery.data?.user?.id]);

  useEffect(() => {
    (async () => {
      DarkMode.init({ cssClass: 'dark-mode' });

      if (Capacitor.isPluginAvailable('Keyboard')) {
        if (Capacitor.getPlatform() === 'ios') {
          Keyboard.setAccessoryBarVisible({ isVisible: true });
        }

        Keyboard.addListener('keyboardDidShow', () => {
          if (Capacitor.getPlatform() === 'ios') {
            document?.activeElement?.scrollIntoView({
              behavior: 'smooth',
              block: 'center',
            });
          }
        });
      }
    })();
  }, []);

  useInitAppsFlyer(canMakeRequests);
  useSetReferrerCookie();
  useSaveReferrerIdDesktop(canMakeRequests);
  useColorModeManage(canMakeRequests);

  useEffect(() => {
    if (import.meta.env.VITE_DISABLE_UPDATING === 'true') {
      return;
    }

    checkMinimumVersionAndUpdateMobile(rollbar.error);
    const unsubscribe = initializeUpdating();

    return unsubscribe;
  }, [rollbar]);

  useEffect(() => {
    CapacitorApp.addListener('appUrlOpen', (event: URLOpenListenerEvent) => {
      const url = new URL(event.url);
      const slug = url.pathname + url.search + url.hash;
      if (slug) {
        navigate(slug);
      }
    });
  }, [navigate]);

  useEffect(() => {
    // Put Posthog on window to make it easier to override experiements
    // window.posthog.featureFlags.override({'experiment-feature-flag-key': 'test'})
    (window as any).posthog = posthog;
  }, [posthog]);

  // biome-ignore lint/correctness/useExhaustiveDependencies(tokens): necessary
  useEffect(() => {
    const user = meQuery.data?.user;
    const metadata = meMetadataQuery.data;

    setSavedUserId(user?.id);
    setSavedMeMetadata(metadata);

    if (
      posthog &&
      user &&
      // This code block will get hit on every navigation even when the data doesn’t change. Try to save posthog events by including a check here.
      (user.id !== savedUserId || !isEqual(metadata, savedMeMetadata))
    ) {
      // Calling `identify` ASAP means feature flag is loaded faster, reducing pop-in
      if (!metadata) {
        posthog.identify(user.id);
      } else {
        const platform = Capacitor.getPlatform();
        const data = {
          platform,
          ...metadata,
        };

        posthog.identify(user.id, data);
        posthog.register({ platform });
      }
    }
  }, [posthog, tokens, meQuery, meMetadataQuery, savedUserId, savedMeMetadata]);

  useEffect(() => {
    posthog?.register({
      withToken: !!tokens,
    });
  }, [posthog, tokens]);

  // biome-ignore lint/correctness/useExhaustiveDependencies(location): necessary
  useEffect(() => {
    posthog?.capture('$pageview');
  }, [posthog, location]);

  useEffect(() => {
    if (tokens) {
      posthog.stopSessionRecording();
    } else {
      posthog.startSessionRecording();
    }
  }, [tokens, posthog]);

  useEffect(() => {
    if (
      !tokens &&
      Capacitor.getPlatform() !== 'web' &&
      !isScheduledMaintenance
    ) {
      identityService.signUpAnonymously();
    }
  }, [tokens, isScheduledMaintenance]);

  useEffect(() => {
    const listener = CapacitorApp.addListener(
      'appStateChange',
      ({ isActive }) => {
        if (Capacitor.isPluginAvailable('PushNotifications') && isActive) {
          PushNotifications.removeAllDeliveredNotifications();
        }
      },
    );

    return () => {
      listener.then(({ remove }) => {
        remove();
      });
    };
  }, []);

  // biome-ignore lint/correctness/useExhaustiveDependencies(tokens): necessary
  useEffect(() => {
    if (canMakeRequests) {
      if (Capacitor.isPluginAvailable('Device')) {
        (async () => {
          try {
            const { identifier } = await Device.getId();
            await saveDevice({ id: identifier });
          } catch (e) {
            if (!(e instanceof TimeoutError)) {
              rollbar.error('Error saving device', e);
            }
          }
        })();
      }

      (async () => {
        try {
          if (Capacitor.isPluginAvailable('PushNotifications')) {
            const { receive } = await PushNotifications.checkPermissions();
            if (receive === 'granted') {
              PushNotifications.register();
            }
          }
        } catch (e) {
          rollbar.error('Error checking push notification permissions', e);
        }
      })();

      if (
        Capacitor.isPluginAvailable('PushNotifications') &&
        Capacitor.isPluginAvailable('Device')
      ) {
        const registrationListenerPromise = PushNotifications.addListener(
          'registration',
          async (token) => {
            try {
              const { identifier } = await Device.getId();
              await saveDevice({
                id: identifier,
                fcmRegistrationToken: token.value,
              });
            } catch (e) {
              if (!(e instanceof TimeoutError)) {
                rollbar.error(
                  'Error in handling notifications registration',
                  e,
                );
              }
            }
          },
        );
        const registrationErrorListenerPromise = PushNotifications.addListener(
          'registrationError',
          (error) => {
            rollbar.error('Notifications registration error', error);
          },
        );

        return () => {
          registrationListenerPromise.then((r) => r.remove());
          registrationErrorListenerPromise.then((r) => r.remove());
        };
      }
    }
    return;
  }, [tokens, rollbar, canMakeRequests]);

  useDeleteEmptyEntry();

  if (isScheduledMaintenance && (tokens || Capacitor.isNativePlatform())) {
    // Doesn’t fully handle sign in page.
    return <ScheduledMaintenance />;
  }

  if (Capacitor.getPlatform() !== 'web' && !tokens) return <div />;

  return <Outlet />;
};

const App = () => {
  const identityIsInit = identityService.useInit();
  const tokens = identityService.useTokens();
  const isPinInitialized = PinStore.useState((s) => s.isInitialized);

  const router = createBrowserRouter([
    {
      path: '',
      element: <Root />,
      errorElement: <ErrorView text="An error has occured." />,
      children: [
        ...(tokens
          ? [
              {
                path: 'onboarding',
                children: [
                  { index: true, element: <Onboarding /> },
                  { path: ':pageId', element: <Onboarding /> },
                ],
              },
              { path: 'subscriptions', element: <Subscriptions /> },
              { path: 'subscriptions/request', element: <FreeRequest /> },
              { path: 'entries', element: <EntrySelector /> },
              {
                path: 'entries/:entryId',
                element: <EntryView />,
                children: [
                  { index: true, element: <Summary /> },
                  { path: 'taskTypes/:taskType', element: <TaskView /> },
                  { path: 'exposures', element: <ExposuresList /> },
                ],
              },
              {
                path: 'profile',
                children: [
                  { path: 'entries', element: <Entries /> },
                  { path: 'appearance', element: <Appearance /> },
                  { path: 'goals', element: <Goals /> },
                  { path: 'notifications', element: <Notifications /> },
                  { path: 'pin', element: <PinSetup /> },
                  { index: true, element: <Profile /> },
                ],
              },
              {
                path: 'insights',
                children: [
                  { index: true, element: <Insights /> },
                  { path: 'thinking-traps', element: <ThinkingTrapsView /> },
                  {
                    path: 'journal-calendar',
                    element: <JournalCalendarView />,
                  },
                ],
              },
              {
                path: 'assessment',
                children: [
                  { path: 'questionnaire', element: <PhqAdsQuestionnaire /> },
                  { path: 'results', element: <AssessmentResults /> },
                ],
              },
              { path: 'exposures', element: <Exposures /> },
            ]
          : []),

        {
          path: 'complete-email-sign-in-native',
          element: <CompleteEmailSignIn isNative />,
        },
        { path: 'complete-email-sign-in', element: <CompleteEmailSignIn /> },
        { path: 'sign-in', element: <SignIn /> },
        { path: 'contact', element: <Contact /> },
        {
          path: 'learn',
          children: [
            { path: ':id', element: <LearnItem /> },
            { index: true, element: <LearnList /> },
          ],
        },
        { path: 'about', element: <About /> },
        { path: 'terms-of-service', element: <TermsOfService /> },
        { path: 'privacy-policy', element: <PrivacyPolicy /> },
        { path: 'how-to-account', element: <HowToAccount /> },
        { path: '', element: tokens ? <Dashboard /> : <Landing /> },
        { path: '*', element: tokens ? <Dashboard /> : <Landing /> },
      ],
    },
  ]);

  return (
    <StrictMode>
      <HelmetProvider>
        <Helmet prioritizeSeoTags>
          <title>Unstuck: CBT Journal</title>
          <meta
            name="description"
            content="Unstuck is a CBT journaling app that helps you overcome anxiety and depression."
          />
        </Helmet>
        <RollbarProvider
          config={{
            accessToken: '02dee15a46ac4d9c8ba22a78a64e5a93',
            nodeSourceMaps: true,
            environment: import.meta.env.MODE,
            payload: { environment: import.meta.env.MODE }, // Needed? Probably not anymore since there’s another field for it.
            codeVersion: currentVersions.version,
          }}
        >
          <RollbarErrorBoundary>
            <ColorModeScript initialColorMode={theme.config.initialColorMode} />
            <ChakraProvider theme={theme}>
              <PersistQueryClientProvider
                client={queryClient}
                persistOptions={{
                  persister,
                  buster: 'a007bb27-d737-4cc4-bc1f-6be13f1f6fac',
                  dehydrateOptions: {
                    shouldDehydrateQuery: (query) => {
                      const cacheKeyAllowlist = [
                        ['me'],
                        ['me', 'checklist'],
                        [
                          'me',
                          'calendar',
                          { dateToday: dayjs().format('YYYY-MM-DD') },
                        ],
                        ['questionnaires', { limit: 1 }],
                      ];

                      if (
                        cacheKeyAllowlist.some((key) =>
                          isEqual(key, query.queryKey),
                        )
                      ) {
                        return query.state.status === 'success';
                      }

                      return false;
                    },
                  },
                }}
              >
                <PostHogProvider
                  apiKey="phc_EAyXoVSa7LQpCaJW8SIUKMrRGCtVK3hJBZZSgIpxrCb"
                  options={{
                    api_host: 'https://app.posthog.com',
                    opt_out_capturing_by_default: import.meta.env.DEV,
                    autocapture: {
                      css_selector_allowlist: ['[data-ph-autocapture]'],
                    },
                    disable_session_recording: true,
                    session_recording: {
                      maskTextSelector: '[data-ph-mask-text]',
                    },
                  }}
                >
                  <Suspense fallback={<div />}>
                    {identityIsInit && isPinInitialized ? (
                      <>
                        <PinModal />
                        <PrivacyCover />
                        <Box>
                          <RouterProvider router={router} />
                        </Box>
                      </>
                    ) : (
                      <div />
                    )}
                  </Suspense>
                </PostHogProvider>
              </PersistQueryClientProvider>
            </ChakraProvider>
          </RollbarErrorBoundary>
        </RollbarProvider>
      </HelmetProvider>
    </StrictMode>
  );
};

const hackAroundTextToVoiceIssueWithChakraToastManager = () => {
  const style = document.createElement('style');
  style.appendChild(
    document.createTextNode(
      `
      #chakra-toast-manager-top {
        visibility: hidden;
      }
      #chakra-toast-manager-top-left {
        visibility: hidden;
      }
      #chakra-toast-manager-top-right {
        visibility: hidden;
      }
      #chakra-toast-manager-bottom {
        visibility: hidden;
      }
      #chakra-toast-manager-bottom-left {
        visibility: hidden;
      }
      #chakra-toast-manager-bottom-right {
        visibility: hidden;
      }
    `,
    ),
  );
  document.body.appendChild(style);

  setTimeout(() => {
    style.remove();
  }, 750);
};

hackAroundTextToVoiceIssueWithChakraToastManager();

const container = document.body.appendChild(document.createElement('div'));
const root = createRoot(container);
root.render(<App />);
