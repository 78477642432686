import { App, type StateChangeListener } from '@capacitor/app';
import { Capacitor } from '@capacitor/core';
import { Deploy } from 'cordova-plugin-ionic';
import dayjs from 'dayjs';
import duration from 'dayjs/plugin/duration';
import ky from 'ky';
import semverLt from 'semver/functions/lt';
import currentVersions from '../public/version.json';

dayjs.extend(duration);

export { currentVersions };

const checkAndInstallUpdate = async () => {
  const update = await Deploy.checkForUpdate();
  if (update.available) {
    await Deploy.downloadUpdate();
    await Deploy.extractUpdate();
    await Deploy.reloadApp();
  }
};

const getLatestVersions = async (): Promise<typeof currentVersions> => {
  const url = `${Capacitor.getPlatform() === 'web' ? '' : 'https://becomeunstuck.app'}/version.json`;
  return ky.get(url).json();
};

const checkBelowMinimumVersion = async () => {
  try {
    const latestVersions = await getLatestVersions();
    return semverLt(
      currentVersions.version,
      latestVersions.minimumRequiredVersion,
    );
  } catch (e) {
    console.log(e);
    // No need to do anything, especially without an internet connection
    return false;
  }
};

export const initializeUpdating = (): (() => void) => {
  if (Capacitor.getPlatform() === 'web') {
    let timeoutId: number;

    const handleBlur = () => {
      timeoutId = setTimeout(() => {
        window.location.reload();
      }, dayjs.duration({ minutes: 30 }).asMilliseconds());
    };

    const handleFocus = () => {
      if (timeoutId) {
        clearTimeout(timeoutId);
      }

      (async () => {
        if (await checkBelowMinimumVersion()) {
          window.location.reload();
        }
      })();
    };

    window.addEventListener('blur', handleBlur);
    window.addEventListener('focus', handleFocus);

    return () => {
      window.removeEventListener('blur', handleBlur);
      window.removeEventListener('focus', handleFocus);
    };
  }

  const handleAppStateChange: StateChangeListener = ({ isActive }) => {
    if (isActive) {
      (async () => {
        if (await checkBelowMinimumVersion()) {
          checkAndInstallUpdate();
        }
      })();
    }
  };

  const appListenerPromise = App.addListener(
    'appStateChange',
    handleAppStateChange,
  );

  return async () => {
    (await appListenerPromise).remove();
  };
};

export const checkMinimumVersionAndUpdateMobile = async (
  onError: (error: Error) => void,
) => {
  try {
    if (
      Capacitor.getPlatform() !== 'web' &&
      (await checkBelowMinimumVersion())
    ) {
      await checkAndInstallUpdate();
    }
  } catch (e) {
    onError(e);
  }
};
