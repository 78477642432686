import { Preferences } from '@capacitor/preferences';

/*
 * Web must be localstorage since identityService depends on a storage listener for multi tab sign out.
 */
export const set = async (key: string, value: unknown): Promise<void> => {
  await Preferences.set({ key, value: JSON.stringify(value) });
};

export const get = async <Type>(key: string): Promise<Type | undefined> => {
  const { value } = await Preferences.get({ key });
  if (value == null) return undefined;
  return JSON.parse(value);
};

export const remove = async (key: string) => {
  await Preferences.remove({ key });
};
