import { Box, Flex, Heading, Image } from '@chakra-ui/react';
import type { FC, ReactElement } from 'react';

const ImageWithText: FC<{
  heading: string;
  description: ReactElement;
  additionalContent?: ReactElement;
  image: { src: string; alt: string };
  isImageFirst?: boolean;
}> = ({ heading, description, additionalContent, image, isImageFirst }) => {
  return (
    <Flex
      flexDirection={{
        base: 'column',
        md: isImageFirst ? 'row-reverse' : 'row',
      }}
      alignItems="center"
      gap={{ base: 'hecto', lg: 'tera' }}
      textAlign={{ base: 'center', md: 'left' }}
    >
      <Box flex={1}>
        <Heading
          color="brand.2"
          textStyle={{ base: 'popEmu', lg: 'popOstrich' }}
          mb="centi"
        >
          {heading}
        </Heading>
        <Box textStyle={{ base: 'goose', lg: 'swan' }} mt="regular">
          {description}
        </Box>
        {additionalContent && (
          <Box mt="hecto" textStyle="popDuck">
            {additionalContent}
          </Box>
        )}
      </Box>
      <Box flex={1}>
        <Image
          src={image.src}
          alt={image.alt}
          display="inline-block"
          maxHeight={{ base: '26rem', md: '37.5rem' }}
        />
      </Box>
    </Flex>
  );
};

export default ImageWithText;
