import { Box, Button, Text } from '@chakra-ui/react';
import { useRollbar } from '@rollbar/react';
import Page from '@src/components/Page';
import { persister } from '@src/queryClient';
import { usePostHog } from 'posthog-js/react';
import { type FC, useEffect } from 'react';
import { useNavigate, useRouteError } from 'react-router-dom';

// TODO:
// biome-ignore lint/suspicious/noShadowRestrictedNames: Just get it working
const Error: FC<{ text: string }> = ({ text }) => {
  const navigate = useNavigate();
  const error = useRouteError();
  const rollbar = useRollbar();
  const posthog = usePostHog();

  useEffect(() => {
    rollbar.error('Error caught by error view', error as any);
    posthog?.capture('Error caught by error view');
  }, [rollbar, error, posthog]);
  return (
    <Page
      topMobileNavBarProps={{
        onBack: () => navigate('/'),
        headingText: 'Not found',
      }}
      content={
        <Box textAlign="center">
          <Text mt="20vh" mb="regular">
            {text}
          </Text>
          <Button
            onClick={() => {
              window.location.href = '/';
              persister.removeClient();
            }}
          >
            Return to the dashboard
          </Button>
        </Box>
      }
    />
  );
};

export default Error;
