export const referrersById: Record<
  string,
  { isDiscount?: boolean; isSeparateLanding?: boolean }
> = {
  fb: {},
  ig: {},
  rtpost: {},
  rt: {},
  theshaya: {},
  tiktok: {},
  recoveryrevival: {},
  siddhi: {
    isDiscount: true,
    isSeparateLanding: true,
  },
};
