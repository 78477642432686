import {
  Box,
  Button,
  Divider,
  Heading,
  Image,
  Link,
  Text,
  useColorMode,
} from '@chakra-ui/react';
import logomark from '@src/assets/logomark.svg';
import logomarkDark from '@src/assets/logomarkDark.svg';
import type { FC } from 'react';
import { Link as RouterLink } from 'react-router-dom';

const NativeOpenInApp: FC = () => {
  const { colorMode } = useColorMode();

  // Don’t remember why this needs to be position absolute.
  return (
    <Box textAlign="center" position="absolute" height="100%" width="100%">
      <Image
        src={colorMode === 'dark' ? logomarkDark : logomark}
        alt="Unstuck"
        width="50px"
        mt="hecto"
        mb="kilo"
        display="inline-block"
      />
      <Heading textStyle="popFlamingo" mb="hecto">
        Complete your sign in
      </Heading>

      <Heading textStyle="popSwan">Open in the Unstuck app</Heading>
      <Button
        as="a"
        href={`https://unstuck.onrender.com${location.pathname}${location.search}${location.hash}`}
        width="100%"
        size="lg"
        my="deka"
      >
        Continue in the app
      </Button>
      <Box textStyle="popDuck">Having issues opening in the app?</Box>
      <Text>
        Some browsers require you to select an option called{' '}
        <strong>Open in app</strong> from the browser menu.
      </Text>
      <Divider my="hecto" />
      <Box>
        <Heading textStyle="popSwan" my="deci">
          Or, continue on the browser
        </Heading>
        <Link
          as={RouterLink}
          to={`/complete-email-sign-in${location.search}${location.hash}`}
        >
          Sign in on this browser
        </Link>
      </Box>
    </Box>
  );
};

export default NativeOpenInApp;
