import { Box, Link } from '@chakra-ui/react';
import {
  emailContactUrl,
  facebookUrl,
  instagramUrl,
  redditUrl,
} from '@src/constants';
import { Facebook, Instagram, Mail, Reddit } from '@src/icons';
import type { FC } from 'react';

const Socials: FC = () => {
  return (
    <Box textStyle={{ base: 'popPuffin', md: 'popDuck' }}>
      <Box as="span" mr={{ base: 0, md: 'deci' }}>
        Connect with us:
      </Box>
      <Box
        as="span"
        display={{ base: 'block', md: 'inline' }}
        mt={{ base: 'deci', md: 0 }}
      >
        {[
          {
            IconComponent: Instagram,
            href: instagramUrl,
          },
          {
            IconComponent: Reddit,
            href: redditUrl,
          },
          {
            IconComponent: Facebook,
            href: facebookUrl,
          },
          {
            IconComponent: Mail,
            href: emailContactUrl,
          },
        ].map(({ IconComponent, href }) => (
          <Link key={href} target="_blank" href={href} px="deci">
            <IconComponent
              boxSize="1.375rem"
              mr="deci"
              color="neutral.1.base"
            />
          </Link>
        ))}
      </Box>
    </Box>
  );
};

export default Socials;
