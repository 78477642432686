import { Box, type ChakraProps, forwardRef } from '@chakra-ui/react';

interface Props {
  variant?: 'base' | 'colorful';
}

const Card = forwardRef<ChakraProps & Props, any>(
  ({ variant = 'base', ...props }, ref) => {
    return (
      <Box
        bg="neutral.bg.0"
        p="deci"
        // Typically, cards have stacked text with centering. The global line-height affects this and creates more spacing at the top. This happens in the multi-line cases more than the single line cases.
        // Adjusting line height is a partial fix, but we still need to add more padding to the bottom.
        lineHeight="normal"
        borderRadius="md"
        borderColor={variant === 'colorful' ? 'transparent' : 'neutral.8'}
        bgGradient={
          variant === 'colorful'
            ? // Overlay a transparent white box in the middle over the gradient.
              // This is a workaround to get a gradient border that works with a border radius. See https://gist.github.com/stereokai/36dc0095b9d24ce93b045e2ddc60d7a0
              'linear-gradient(neutral.bg.0, var(--chakra-colors-neutral-bg-0)), linear-gradient(135deg, var(--chakra-colors-brand-gradient-2-start) 0%, var(--chakra-colors-brand-gradient-2-end) 100%)'
            : 'none'
        }
        sx={variant === 'colorful' ? { backgroundOrigin: 'border-box' } : {}}
        bgClip={
          variant === 'colorful' ? 'padding-box, border-box' : 'border-box'
        }
        borderWidth="1px"
        ref={ref}
        {...props}
      />
    );
  },
);

export default Card;
